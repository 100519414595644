import React from "react"

import Layout from "@src/components/layout"
import ReportsSection from "@src/sections/reports"


const Reports = (): React.ReactElement => {
  return (
    <Layout>
      <ReportsSection />
    </Layout>
  )
}

export default Reports
