import React from "react"
import tw from "twin.macro"
import { useQuery } from "@apollo/client"

import CampaignNavigation from "@src/components/campaign/navigation"
import InfoBarWithContext from "@src/components/infoBar/infoBarWithContext"
import CustomContainer from "@src/components/customContainer"
import Loading from "@src/components/loading"
import { GET_CAMPAIGNS, Campaign } from "@src/queries/campaign"
import ErrorComponent from "@src/components/ui/error"
import { CampaignRunStatus } from "@src/queries/campaignRun"
import { Localization } from "@src/localization"
import CustomButton, { ButtonColors } from "@src/components/ui/button"
import config from "@src/config"
import { getToken } from "@src/utils/auth"
import { getLastModified } from "@src/utils/lastModified"
import { serverToClient } from "@src/utils/timezone"

const Reports = (): React.ReactElement => {
  // page content
  const campaignsContent = React.useContext(Localization).campaigns

  // get campaigns
  const { loading, error, data } = useQuery(GET_CAMPAIGNS)

  // show loading
  if (loading) return <Loading withOverlay={true} />

  // show error page
  if (error)
    return (
      <ErrorComponent msg="There is an error fetching campaigns. Please refresh your page or try again later." />
    )

  // sort campaigns
  const reportCampaigns: Campaign[] = []
  if (data && data.campaigns && data.campaigns.length > 0) {
    data.campaigns.slice().forEach((oneCamp: Campaign) => {
      if (oneCamp.campaignRuns && oneCamp.campaignRuns.length > 0) {
        oneCamp.campaignRuns.forEach(oneRun => {
          ;[CampaignRunStatus.Approved, CampaignRunStatus.Sent].includes(
            oneRun.status
          ) &&
            reportCampaigns.push({
              ...oneCamp,
              campaignRuns: [oneRun],
            })
        })
      }
    })
  }
  if (reportCampaigns && reportCampaigns.length > 0) {
    reportCampaigns.sort((a: Campaign, b: Campaign) => {
      return getLastModified(a) > getLastModified(b) ? -1 : 1
    })
  }

  return (
    <CustomContainer customCss={tw`bg-white`}>
      <div css={[tw`flex flex-col w-full`]}>
        {/* Campaign navigation */}
        <CampaignNavigation />

        {/* Campaign orange info bar */}
        <InfoBarWithContext />

        <div tw="flex flex-col self-center w-full xl:w-10/12 min-h-md bg-white border border-gray-400 rounded-lg mb-4">
          {reportCampaigns.map((oneCampaign: Campaign) => {
            // campaign last edited
            const lastEdited = serverToClient(getLastModified(oneCampaign)).format(
              "llll z"
            )

            // campaign run identifier
            const crId =
              oneCampaign.campaignRuns && oneCampaign.campaignRuns.length > 0
                ? oneCampaign.campaignRuns[0].id
                : ""

            // proof file url
            const proofFileUrl =
              crId &&
              oneCampaign.campaignRuns[0].templateRuns &&
              oneCampaign.campaignRuns[0].templateRuns.length > 0 &&
              oneCampaign.campaignRuns[0].templateRuns[0].proofFileUrl

            return (
              <div
                tw="flex w-full border-b border-gray-400 last:border-b-0 justify-between"
                key={`${oneCampaign.id}${crId}`}
              >
                <div tw="flex flex-col py-3 px-3">
                  <div tw="text-xs text-gray-800">
                    {campaignsContent.campaign}:{" "}
                    <span tw="font-bold text-black">{oneCampaign.name}</span>
                  </div>

                  {oneCampaign.templates.map(template => (
                    <div tw="text-xs text-gray-800" key={template.id}>
                      {campaignsContent.template}:{" "}
                      <span tw="font-bold text-black">{template.name}</span>
                    </div>
                  ))}

                  <div tw="text-xs text-gray-800">
                    {campaignsContent.edited}{" "}
                    <span tw="font-bold text-black">{lastEdited}</span>{" "}
                  </div>
                </div>
                <div css={[tw`flex items-center`]}>
                  <CustomButton
                    color={ButtonColors.white}
                    customCss={tw`mr-2`}
                    onClick={() => {
                      crId &&
                        window.open(
                          `${
                            config.api_base_url
                          }/v1/campaigns/${crId}/patients/download?sessionid=${getToken()}`
                        )
                    }}
                  >
                    {campaignsContent.downloadPatient}
                  </CustomButton>
                  {proofFileUrl && (
                    <CustomButton
                      color={ButtonColors.white}
                      customCss={tw`mr-2`}
                      onClick={() => {
                        window.open(
                          `${config.storage_url}/${config.bucket}/proofs/${proofFileUrl}`
                        )
                      }}
                    >
                      {campaignsContent.downloadProof}
                    </CustomButton>
                  )}
                </div>
              </div>
            )
          })}
          <div tw="px-4 py-8 text-center">You have no other reports</div>
        </div>
      </div>
    </CustomContainer>
  )
}

export default Reports
